<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">Which one of the following processes is exothermic?</p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'CalPolyPracticeExam3Q9',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: 'Ice melting', value: 'a'},
        {text: 'Water evaporating ', value: 'b'},
        {text: 'Boiling soup', value: 'c'},
        {text: 'Condensation of water vapor', value: 'd'},
        {
          text: 'Ammonium thiocyanate and barium hydroxide are mixed at 25°C: the temperature drops',
          value: 'e',
        },
      ],
    };
  },
};
</script>
